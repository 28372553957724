<div class="main">

    <div class="vessel-details-block">
        <div class="vsl-img-block">
            <img src="../../../../../assets/danish-shipping-assets/ship.png" alt="Ship Image" class="vessel-image">
        </div>
        <div class="vsl-details-items" *ngIf="vesselData$|async as vessel">
            <div class="title-header">
                <label for="">{{vessel?.vesselName}}</label>
            </div>
            <div class="details-items">
                <div class="details-l-block">
                    <div class="dtl-item">
                        <label for="">IMO</label>
                        <span>{{vessel?.imo}}</span>
                    </div>
                  <div class="dtl-item">
                    <label for="">DWT</label>
                    <span>{{vessel?.vesselDWT}} tonnes</span>
                 </div>
                  <div class="dtl-item">
                    <label for="">Recycling location</label>
                    <span>{{step3Data!=null?step3Data[0].country:"-"}}</span>
                  </div>
                  <div class="dtl-item">
                    <label for="">Vessel Lifetime</label>
                    <span>{{vessel?.lifeTime}} years</span>
                  </div>
                   
                    
                </div>
                <div class="details-r-block">
                    <div class="dtl-item">
                        <label for="">Vessel type</label>
                        <span>{{vessel?.vesselType}}</span>
                    </div>
                    <div class="dtl-item">
                        <label for="">Fuel type</label>
                        <span>{{step2Data!=null?step2Data[0].optionTitle:"-"}}</span>
                    </div>
                    <div class="dtl-item">
                        <label for="">Recycling method</label>
                        <span>{{step3Data!=null?step3Data[0].optionTitle:"-"}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lifecycle-screening-card-content" *ngIf="activeTab=='active'">
            
        <div class="lifecycle-head-block">
            <div class="lifecycle-title-block">
                <label class="lifecycle-title">Lifecycle Screening:  </label>
                
            </div>
            <div class="lifecycle-icon">
                <label class="lifecycle-value">
                    {{ (resultScopes?.scope1?.fuelImpacts[0]?.impact != null ? resultScopes?.scope1?.fuelImpacts[0]?.impact : 0) + 
                       (resultScopes?.scope3?.recyclingImpact != null ? resultScopes?.scope3?.recyclingImpact : 0) + 
                       (resultScopes?.scope3?.totalClimateImpact != null ? resultScopes?.scope3?.totalClimateImpact : 0) 
                    | number: '1.0-2' }}
                    <span class="unit-txt">tCO2eq</span>
                  </label>            </div>
        </div>
    
        <div class="bar-block" *ngIf="vessel?.totalEmission==0">
            <div class="bar-item scope1"></div>
            <div class="bar-item scope2"></div>
            <div class="bar-item scope3"></div>
        </div>
        <div class="bar-block" *ngIf="vessel?.totalEmission!=0">
            <div class="bar-item scope1-light" [ngStyle]="{'width':resultScopesPercentValues?.scope1+'%'}"></div>
            <div class="bar-item scope2-light" [ngStyle]="{'width':resultScopesPercentValues?.scope2+'%'}"></div>
            <div class="bar-item scope3-light" [ngStyle]="{'width':resultScopesPercentValues?.scope3+'%'}"></div>
        </div>
        <div class="scope-data-container">
            <div class="scope-data">
                <div class="scope-title-bloc">
                    <div class="scope-square" [ngClass]="{'scope1':vessel?.totalEmission==0,'scope1-light':vessel?.totalEmission!=0}"></div>
                    <label for="">Scope 1</label>
                </div>
                <div class="scope-value">
                    <label class="lifecycle-title">{{(resultScopes?.scope1?.totalImpact!=null?resultScopes?.scope1?.totalImpact:0) | number: '1.0-2'}}</label>
                    <span class="lifecycle-value">tCO2eq</span>
                </div>
            </div>
            <div class="scope-data">
                <div class="scope-title-bloc">
                    <div class="scope-square" [ngClass]="{'scope2':vessel?.totalEmission==0,'scope2-light':vessel?.totalEmission!=0}"></div>
                    <label for="">Scope 2</label>
                </div>
                <div class="scope-value">
                    <label class="lifecycle-title">{{(resultScopes?.scope2?.totalImpact!=null?resultScopes?.scope2?.totalImpact:0) | number: '1.0-2'}}</label>
                    <span class="lifecycle-value">tCO2eq</span>
                </div>
            </div>
            <div class="scope-data">
                <div class="scope-title-bloc">
                    <div class="scope-square" [ngClass]="{'scope3':vessel?.totalEmission==0,'scope3-light':vessel?.totalEmission!=0}"></div>
                    <label for="">Scope 3</label>
                </div>
                <div class="scope-value">
                    <label class="lifecycle-title">{{(resultScopes?.scope3?.scope3Result!=null?resultScopes?.scope3?.scope3Result:0) | number: '1.0-2'}}</label>
                    <span class="lifecycle-value">tCO2eq</span>
                </div>  
            </div>
        </div>
    
        <div class="steps-result-blocks">
            <div class="row gx-4 gy-4"> <!-- Add gx-4 for horizontal spacing and gy-4 for vertical spacing -->
                <div class="col-md-4">
                    <div class="result-card">
                        <label class="res-card-title">Baseline building scenario</label>
                        <p class="res-card-details">
                            This scenario represents the default set of assumptions and parameters for the vessel’s construction phase.
                        </p>
                        <div class="res-card-result">
                            <span class="res-txt">Results:</span>
                            <div class="res-value">
                                <span class="res-number-value">
                                    {{ (resultScopes?.scope3?.totalClimateImpact != null ? resultScopes?.scope3?.totalClimateImpact : 0) | number: '1.0-2' }}
                                  </span>
                                <span class="res-unit-value">tCO2eq</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="result-card">
                        <label class="res-card-title">Fuel Climate Impact Assessment</label>
                        <p class="res-card-details">
                            Comparison of climate impacts generated by different fuel options.
                        </p>
                        <div class="res-card-result">
                            <span class="res-txt">Results:</span>
                            <div class="res-value">
                                <span class="res-number-value">
                                    {{(resultScopes?.scope1?.fuelImpacts[0]?.impact != null ? resultScopes?.scope1?.fuelImpacts[0]?.impact : 0) | number: '1.0-2'}}
                                </span>                                <span class="res-unit-value">tCO2eq</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="result-card">
                        <label class="res-card-title">Recycling Climate Impact</label>
                        <p class="res-card-details">
                            Screening-level assessment of climate impacts from the vessel's recycling process.
                        </p>
                        <div class="res-card-result">
                            <span class="res-txt">Results:</span>
                            <div class="res-value">
                                <span class="res-number-value">
                                    {{ (resultScopes?.scope3?.recyclingImpact != null ? resultScopes?.scope3?.recyclingImpact : 0) | number: '1.0-2' }}
                                  </span>
                                 <span class="res-unit-value">tCO2eq</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    
    <div class="chart-container" *ngIf="false">
        <div class="description-text-chart-container">
            <div style="display: flex;flex-direction: column;">
                <p class="chart-title">Lifecycle Comparison</p>
                <p class="chart-text-description">This graphic presents the vessel’s lifecycle screening results, displaying emissions data across Scope 1, Scope 2, and Scope 3.</p>
            </div>
            <div>
                <div class="icon-buttons">
                    <button class="button-left">
                      <svg fill="#475467" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                        <path d="M14.657 18.657a1 1 0 0 1-.707-.293l-5.657-5.657a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 0 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-.707 1.707z"></path>
                      </svg>
                    </button>
                    <button class="button-right">
                      <svg fill="#475467" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                        <path d="M9.343 18.657a1 1 0 0 1-.707-1.707l4.95-4.95-4.95-4.95a1 1 0 0 1 1.414-1.414l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-.707.293z"></path>
                      </svg>
                    </button>
                  </div>
                  
            </div>
        </div>
    </div>

    <div class="chart-container" *ngIf="false">
        <div class="description-text-chart-container">
            <div style="display: flex;flex-direction: column;">
                <p class="result-card-title">Baseline building scenario</p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Vessel Type</p>
                <p class="value">Cargo</p>
            </div>
            <div style="display: flex;flex-direction: column;margin-left: 50%;">
                <p class="label">DWT</p>
                <p class="value">65,757 tonnes</p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Built location</p>
                <p class="value">Ukraine</p>
            </div>
            <div style="display: flex;flex-direction: column;margin-left: 49.5%;">
                <p class="label">Length</p>
                <p class="value">555 M </p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Beam</p>
                <p class="value">666 M</p>
            </div>
        </div>
        <div class="result-card-footer">
            <span class="footer-label">Results:</span>
            <span class="footer-value">8,766</span>
            <span class="footer-unit">tCO2eq</span>
          </div>
    </div>
    <div class="footer-page-divs" *ngIf="false">
        <div class="first-card">
            <p class="fuel-title">Fuel Climate Impact Assessment</p>
            <p class="label-option">Option</p>
            <p class="value-option">E-methanol</p>
            <div class="result-card-footer">
                <span class="footer-label">Results:</span>
                <span class="footer-value">8,766</span>
                <span class="footer-unit">tCO2eq</span>
              </div>
        </div>
        <div class="second-card">
            <p class="fuel-title">Recycling Method and Country</p>
            <div style="display: flex;">
                <div style="display: flex;flex-direction: column;">
                    <p class="label-option">Country</p>
                    <p class="value-option">India</p>
                </div>
                <div style="display: flex;flex-direction: column;margin-left: 25%;">
                    <p class="label-option">Method</p>
                    <p class="value-option">Oxy-fuel</p>
                </div>
            </div>


            <div class="result-card-footer">
                <span class="footer-label">Results:</span>
                <span class="footer-value">8,766</span>
                <span class="footer-unit">tCO2eq</span>
              </div>
        </div>
    </div>
</div>
