<div class="emission-block">
    <div class="vessel-value-card">
        <div class="icon-block">
            <img src="../../../assets/danish-shipping-assets/ship-icon.svg" alt="">
        </div>
        <label for="" class="vs-title">vessel</label>
        <label for="" class="vs-number">{{count!=null&&count!=undefined?count:0}}</label>
    </div>
    <div class="emission-container">
        <div class="chart-section">
          <div class="pie-chart" [style.background]="generateConicGradient(stats)">
          </div>
          <div class="center-circle"></div>
        </div>
        <div class="info-section">
            <div class="total-emission">
                <p class="total-emission-txt">Total emission: <span class="value-number-text">{{stats?.totalEmission | number: '1.0-2'}} <span class="unit-text">tCO₂eq</span></span></p>
                <img src="../../../assets/danish-shipping-assets/help-icon.svg" alt="">
            </div>
          <div class="details">
            <div class="detail-item">
                <div class="scope-bloc">
                    <div class="color-box scope1"></div>
                    <label class="scope-txt">Scope 1 <span class="scope-percentage-txt">({{stats?.scope1Percentage | number: '1.0-2'}}%)</span></label>
                </div>
                <div class="scope-value-bloc">
                    <span class="value-number-text">{{stats?.scope1Emission | number: '1.0-2'}} <span class="unit-text">tCO₂eq</span></span>
                </div>
                
            </div>
            <div class="detail-item">
                <div class="scope-bloc">
                    <div class="color-box scope2"></div>
                    <label class="scope-txt">Scope 2 <span class="scope-percentage-txt">({{stats?.scope2Percentage | number: '1.0-2'}}%)</span></label>
                </div>
                <div class="scope-value-bloc">
                    <span class="value-number-text">{{stats?.scope2Emission | number: '1.0-2'}} <span class="unit-text">tCO₂eq</span></span>
                </div>
              
            </div>
            <div class="detail-item">
                <div class="scope-bloc">
                    <div class="color-box scope3"></div>
                    <label class="scope-txt">Scope 3 <span class="scope-percentage-txt">({{stats?.scope3Percentage | number: '1.0-2'}}%)</span></label>
                </div>
               
                <div class="scope-value-bloc">
                    <span class="value-number-text">{{stats?.scope3Emission | number: '1.0-2'}} <span class="unit-text">tCO₂eq</span></span>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>