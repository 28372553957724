import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { CompanyAssessmentService } from '../../../../../services/company-assessment.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { log } from 'console';

@Component({
  selector: 'app-dc-process-modal',
  templateUrl: './dc-process-modal.component.html',
  styleUrls: ['./dc-process-modal.component.scss']
})
export class DcProcessModalComponent implements OnInit {
  @Output() toggleModal=new EventEmitter();
  @Input() selectedCategory:any=null;
  @Input() selectedProcess:any=null;
  @Input() compAssId:any=null;
  public currentProcess:any;
  ote: string = ''; // The ngModel-bound variable
  maxCharacters: number = 400; // Maximum character limit
  isTextareaDisabled: boolean = false;
  public processForm:FormGroup=this.fb.group({})
  public groupData:any=null;
  public selectedTarget: any=null;
  public selectedGroup: any=null;
  public selectedSubGroup: any=null;
  public targetType="";
  public file: File;
  public filename:any =null;
  public fileUrl:any =null;
  public note="";
  public defaultTree=null;
  public defaultExtraData:any=null;
  public countryList=[];
  public canWorkFromHome=false;
  public folder_icon = false;
  public folderStates: boolean[] = [];
  public folderSubItemStates: boolean[] = [];
  public searchText="";
  public dbVersion="";
  constructor(
    private fb: FormBuilder,
    private companyAssessmentService:CompanyAssessmentService,
    private uploadService: FileUploadService
    ) { }

  ngOnInit(): void {
    this.currentProcess=this.formData.filter(x=>x.name.toLocaleLowerCase()==this.selectedCategory.name.toLocaleLowerCase())[0]
    if(this.blackList.indexOf(this.selectedCategory.name)!=-1){
      this.currentProcess.attibutes=this.currentProcess.attibutes.filter(x=>x.label!="Amount")
    }
    for (const item of this.currentProcess.attibutes) {
      this.processForm.addControl(item.name, this.fb.control(''));
    }
    this.companyAssessmentService.getAssessmentsById(Number(localStorage.getItem('company_id')),this.compAssId).subscribe(result=>{
      this.dbVersion=result.year;
    });

    this.companyAssessmentService.getGroupMaterial(this.compAssId,this.selectedCategory.name).subscribe(ans=>{
      this.groupData=ans;

      if(this.groupData['companyAssessmentSubGroups'].length==1&&this.groupData['companyAssessmentSubGroups'][0].name=="Flights"){
        this.groupData['companyAssessmentSubGroups'][0].name="Flight and Accomodation"
      }
      
      if(this.selectedProcess!=null){
        this.defaultTree=JSON.parse(this.selectedProcess.selectedProcessTrace);
        this.defaultExtraData=JSON.parse(this.selectedProcess.data);

        for (let key in this.defaultExtraData) {
          this.processForm.controls[key].setValue(this.defaultExtraData[key]);
        }

        let x=this.groupData!['companyAssessmentSubGroups'].filter(_x=>_x.id==this.defaultTree![1])[0]
        this.selectedGroup=x.id;
        x=x['companyAssessmentEndGroups'].filter(_x=>_x.id==this.defaultTree![2])[0]

        this.selectedSubGroup=x.id;
        this.selectedTarget=x;
        if(this.defaultTree![3]!=null&&this.defaultTree![3]!=undefined){
          this.selectedTarget=x['companyAssessmentEmissionProcesses'].filter(_x=>_x.id==this.defaultTree![3])[0];
          this.targetType="process";
          this.companyAssessmentService.getcountiesHotelFactor().subscribe(ans=>{
            this.formData[9].attibutes[2].data  = ans;
          })
        }

        this.note=this.selectedProcess!['note'];
        this.processForm.controls['dataQuality'].setValue(this.selectedProcess!['dataQuality']);
        this.processForm.controls['quantity'].setValue(this.selectedProcess!['quantity']);
        this.filename=this.selectedProcess!['documentation'];
        this.fileUrl=this.selectedProcess!['documentationUrl'];
      }
     
    },err=>{

    });

  }

  public onFilechange(event: any) {
    this.file = event.target.files[0]
    this.filename=this.file.name;
    if(this.file.name.length>15){
      this.filename=this.file.name.substring(0, 12)+"...";
    }
    this.filename+=this.file.name.split('?')[0].split('.').pop();
    this.uploadService.uploadFile(event.target.files[0], 0).subscribe((res) => {
      this.fileUrl = res.fileName;
    });
  }

  capitalizeFirstLetter(input: string): string {
    return input?.charAt(0)?.toUpperCase() + input?.slice(1);
  }
  checkCharacterCount() {
    if (this.note.length > this.maxCharacters) {
      // Truncate the text to the maximum character limit
      this.note = this.note.slice(0, this.maxCharacters);
    }
    this.isTextareaDisabled = this.note.length >= this.maxCharacters;
  }
  
  public selectEndgroup(item,type){
    if(item.companyAssessmentEmissionProcesses==null||item.companyAssessmentEmissionProcesses.length==0){
      this.selectedTarget=item;
      this.targetType='endGroup';
      if(type=='fromSearch'){
        this.selectedGroup=this.selectedSearch[1];
        this.selectedSubGroup=this.selectedSearch[0].id;
      }
    }
    if(this.selectedCategory.name=="3.6.1 - Flight and Accommodation"){
      this.companyAssessmentService.getcountiesHotelFactor().subscribe(ans=>{
        this.formData[9].attibutes[2].data  = ans;
      })
    }
    if(this.selectedCategory.name=="Home office"){
      this.companyAssessmentService.getCountries().subscribe(ans=>{
        this.formData[14].attibutes[0].data  = ans;
      })
    }

    this.folderSubItemStates[item.id] = !this.folderSubItemStates[item.id];

  }
  public validnumberMonths=true;
  public missingInputs=false;
  public save() {
    this.formValid()
    if(this.missingInputs==true){
      return;
    }
    this.validnumberMonths=true;
    if (this.processForm.contains('numberMonths')&&(parseInt(this.processForm.controls['numberMonths'].value)<0||parseInt(this.processForm.controls['numberMonths'].value)>12)) {
      this.validnumberMonths=false
      return;
    }
    
      let trace = { 1: this.selectedGroup, 2: this.selectedSubGroup };

      if (this.targetType != "endGroup" && this.selectedTarget.id != this.selectedSubGroup) {
          trace[3] = this.selectedTarget!['id'];
      }

      let extraData = this.processForm.value;

      delete extraData['dataQuality'];
      delete extraData['documentation'];
      for (let key in extraData) {
          if (extraData[key] === "") {
              extraData[key] = 0;
          }
          if (!isNaN(extraData[key])) {
              extraData[key] = parseInt(extraData[key]);
          }
      }
        
      if (this.selectedProcess == null) {
          let companyAssessmentProcess = {
              selectedProcessName: this.selectedTarget!['name'],
              selectedProcessId: this.selectedTarget != null ? this.selectedTarget['id'] : 0,
              isProcess: this.targetType == "process" ? true : false,
              documentation: this.filename,
              documentationUrl: this.fileUrl,
              quantity: Number(this.processForm.controls['quantity'].value),
              unit: this.selectedTarget.unit,
              dataQuality: this.processForm.controls['dataQuality'].value,
              note: this.note,
              data: JSON.stringify(extraData),
              selectedProcessTrace: JSON.stringify(trace)
          };
          this.companyAssessmentService.addDataCollectionProcess(this.compAssId, this.selectedCategory.id, companyAssessmentProcess).subscribe(ans => {
              this.closeModal(true);
          }, err => {
          });
      } else {
          let companyAssessmentProcess = {
              dataCollectionProcessId: this.selectedProcess.id,
              selectedProcessId: this.selectedTarget['id'],
              selectedProcessName: this.selectedTarget!['name'],
              selectedProcessTrace: JSON.stringify(trace),
              isProcess: this.targetType == "process" ? true : false,
              type: this.targetType,
              documentation: this.filename,
              documentationUrl: this.fileUrl,
              quantity: Number(this.processForm.controls['quantity'].value),
              //unit: this.processForm.controls['unit'],
              unit: this.selectedTarget.unit,
              dataQuality: this.processForm.controls['dataQuality'].value,
              note: this.note,
              data: JSON.stringify(extraData)


              
          };
        
          this.companyAssessmentService.updateDataCollectionProcess(this.compAssId, this.selectedCategory.id, this.selectedProcess.id, companyAssessmentProcess).subscribe(ans => {
              this.closeModal(true);
          }, err => {
          });
      }
    
}

  public formValid(){
    let oxForm=this.processForm.value;
    delete oxForm.documentation;
    Object.keys(oxForm).forEach((key)=>{ 
      const element = oxForm[key]; 
      if(key!="workingFromHome"){
        if(element==""||element==null){
          this.missingInputs=true;
        }
        if(key=="workingRegime" && element=="Part time" && (oxForm["workingFromHome"]==''||oxForm["workingFromHome"]==null)){
          this.missingInputs=true;
        }
      }
    })
  }

  public dismissAlert(){
    this.missingInputs=false;
  }


  public closeModal(verdict){
    this.toggleModal.emit(verdict);
  }

  public selectQuality(qdItem,target){
    this.processForm.controls[target.name].setValue(qdItem);
    if(target.name=="workingRegime"&&qdItem=="Part time"){
      this.canWorkFromHome=true
    }else if(target.name=="workingRegime"&&qdItem=="Full time"){
      this.canWorkFromHome=false
    }
  }
  embededKey='';
  searchData:any=[]
  searchTrace:any=[];
  selectedSearch:any=null;
  leafIdentifidedByTrace:any=[]
  public makeSearch(){
    this.searchTrace=[];
    for (let i = 0; i < this.groupData?.companyAssessmentSubGroups.length; i++) {
      if(this.groupData?.companyAssessmentSubGroups[i].companyAssessmentEndGroups[0].companyAssessmentEmissionProcesses.length>0){
        this.groupData?.companyAssessmentSubGroups[i].companyAssessmentEndGroups.forEach(element => {
          element.companyAssessmentEmissionProcesses.forEach(subElement => {
            if(subElement.name.toLowerCase().includes(this.searchText.toLowerCase())){
              this.searchTrace.push([subElement,element.id,this.groupData?.companyAssessmentSubGroups[i].id]);
            }
          });
        });
      }else{
        for (let egIdx = 0; egIdx < this.groupData?.companyAssessmentSubGroups[i].companyAssessmentEndGroups.length; egIdx++) {
          const element = this.groupData?.companyAssessmentSubGroups[i].companyAssessmentEndGroups[egIdx];
          if(element.name.toLowerCase().includes(this.searchText.toLowerCase())){
            this.searchTrace.push([element,this.groupData?.companyAssessmentSubGroups[i].id]);
          }
        }
      }
      
    }
    
  }

  public selectProcessEmission(item){
    this.selectedTarget=item;
    this.targetType='process';
    this.selectedGroup=this.selectedSearch[2];
    this.selectedSubGroup=this.selectedSearch[1];

  }

  public blackList=["Food","3.16 - Other Non-official Categories - Home Office","3.6.1 - Flight and Accommodation"]
  public qualityData=[
    "Monitoring - Personal report",
    "Local Measurement",
    "Sensory Calculation",
    "Projection data",
    "Quantification of qualitatives entities",
    "Reported",
    "Estimated",
    "External Source",
    "Documented  by Supplier",
    "Based on Bibliography"
  ];

  public communAttributes=[
    {
      name:"quantity",
      label:"Amount",
      type:"number",
      showUnit:true,
      required: true
    },{
      name:"documentation",
      label:"Add document (if you have)",
      type:"file"
    },{
      name:"dataQuality",
      label:"Data Quality",
      type:"dropdown",
      placehoderText:"Select your data quality",
      data:this.qualityData,
    }
  ]
  public formData=[
    {
      name:"1.1 - Fuel Stationary Source",
      attibutes:this.communAttributes,
    },{
      name:"1.2 - Refrigerant Leaks",
      attibutes:this.communAttributes,
    },{
      name:"1.3 - Company Vehicles",
      attibutes:this.communAttributes,
    },{
      name:"2 - Purchased Electricity, Heat, Steam & Cooling",
      attibutes:this.communAttributes,
    },{
      name:"3.3.1 - Fuel- and Energy-Related Activities - WTT",
      attibutes:this.communAttributes,
    },{
      name:"T&D - transmission and distribution",
      attibutes:this.communAttributes,
    },{
      name:"3.5.2 - Water Generated in Operations",
      attibutes:this.communAttributes,
    },{
      name:"3.1 - Purchased Goods and Services - Material Use",
      attibutes:this.communAttributes,
    },{
      name:"3.5.1 - Waste Generated in Operations",
      attibutes:this.communAttributes,
    },{
      name:"3.6.1 - Flight and Accommodation",
      attibutes:[...[
        {
          name:"occupiedRooms",
          label:"Filled number of occupied rooms",
          type:"number",
          showUnit:false,
          required: true
        },{
          name:"numberOfNights",
          label:"Fill number of nights",
          type:"number",
          showUnit:false,
          required: true
        },
        {
          name:"country",
          label:"Choose the county you traveled to",
          type:"dropdown",
          placeholderText:"Select country",
          data:this.countryList,
          required: true
        },{
          name:"quantity",
          label:"Distance flown",
          type:"number",
          showUnit:false,
          required: true
        },
      ],...this.communAttributes],
    },{
      name:"3.6 - Business Travel",
      attibutes:this.communAttributes,
    },{
      name:"3.4 - Upstream Transportation and Distribution - Freighting Goods",
      attibutes:this.communAttributes,
    },{
      name:"3.7 - Employee Commuting",
      attibutes:this.communAttributes,
    },{
      name:"Food",
      attibutes:this.communAttributes,
    },{
      name:"3.16 - Other Non-official Categories - Home Office",
      attibutes:[...[
        {
          name:"country",
          label:"Country",
          type:"dropdown",
          placeholderText:"Select country",
          data:this.countryList,
          required: true
        },{
          name:"quantity",
          label:"Chose number of employes",
          type:"number",
          required: true
        },{
          name:"workingRegime",
          label:"Working regime",
          type:"dropdown",
          placeholderText:"Select working regime",
          data:["Full time","Part time"],
          required: true
        },{
          name:"workingFromHome",
          label:"% of time working from home",
          type:"number"
        },{
          name:"numberMonths",
          label:"Number of months",
          type:"number",
          required: true
        },
      ],...this.communAttributes],
    }
  ]
  handleItemClick(itemId: any) {

    this.selectedGroup = itemId;
    this.folderStates[itemId] = !this.folderStates[itemId];

  }
}
