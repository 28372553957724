import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { UserState } from '../reducers/user.reducers';

export const selectCurrentUserState = createFeatureSelector<AppState,UserState>('user');

export const selectCurrentUser = createSelector(
    selectCurrentUserState,
    (state: UserState) => state?.currentUser
);


export const selectCompanyId = createSelector(
    selectCurrentUserState,
    (state: UserState) => state.currentUser?.companyId
);

export const selectUserInfo = createSelector(
    selectCurrentUserState,
    (state: UserState) => state?.userInfo
);

export const selectUserId = createSelector(
    selectCurrentUserState,
    (state: UserState) => state?.currentUser?.userId
);

export const selectVerificationCode = createSelector(
    selectCurrentUserState,
    (state: UserState) => state?.currentUser?.verificationCode
);