<div class="reflow-form">
    <div class="fixed-form-header">
      <div class="header-content">
        <label class="form-title">Edit Option :</label>
        <em class="fa fa-times form-close" (click)="hideModal()"></em>
      </div>
    </div>
    <div class="form-container">
      <form [formGroup]="OptionToEdit">
        <label class="section-title">FUEL AND RECYCLING OPTION INFO</label>
  
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="title"
              required
            />
            <label class="float-label">Option Title</label>
            <small *ngIf="submitted && OptionToEdit.get('title')?.errors?.required">
              *Option title is required
            </small>
          </div>
        </div>
  
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="number"
              formControlName="coefficient"
              required
            />
            <label class="float-label">Coefficient</label>
            <small *ngIf="submitted && OptionToEdit.get('coefficient')?.errors?.required">
              *Coefficient is required
            </small>
          </div>
        </div>
  
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="number"
              formControlName="percentage"
              required
            />
            <label class="float-label">Percentage</label>
            <small *ngIf="submitted && OptionToEdit.get('percentage')?.errors?.required">
              *Percentage is required
            </small>
          </div>
        </div>
  
        <div class="row">
          <div class="field">
            <label for="scenarioCategorySelect">Scenario Category</label>
            <select
              id="scenarioCategorySelect"
              class="re-select"
              formControlName="scenarioCategoryId"
              required
            >
              <option value="" disabled>Select a Option Category</option>
              <option *ngFor="let category of scenarioCategories" [value]="category.id">
                {{ category.title }}
              </option>
            </select>
            <small *ngIf="submitted && OptionToEdit.get('scenarioCategoryId')?.errors?.required">
              *Scenario Category is required
            </small>
          </div>
        </div>
        
      </form>
    </div>
    <div class="fixed-form-footer">
      <div class="footer-content">
        <button class="btn-solid default" (click)="hideModal()">Close</button>
        <button class="btn-solid secondary" (click)="onSubmit()">Save Changes</button>
      </div>
    </div>
  </div>
  