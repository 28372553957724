import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ScenariosState } from '../../store/reducers/scenarios.reducers';
import { Store } from '@ngrx/store';
import { ScenariosActions } from '../../store/actions/scenarios.actions';
import { Observable } from 'rxjs';
import { selectMaterialOptions } from '../../store/selectors/scenarios.selectors';

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.scss']
})
export class MaterialModalComponent implements OnInit {

  materialOptions$:Observable<any>;
  selectedSubGroup:any=null;
  selectedEndgroup:any=null;
  selectedCountry:any=null;
  @Input() parentId:any=0;

  canShowDetails:boolean=false;


  // State Variables
  public showSub: boolean = true;
  public showPrivate: boolean = false;
  public canShowEndGroups: boolean = true;
  public canShowSharedDetails: boolean = true;

  public subGroups: any[] = [];
  public privateSubGroup: any[] = [];
  public myPorjectsSubfolders: any[] = [];
  public locations: any[] = [];
  public endGroups: any[] = [];
  public searchResults: any[] = [];
  public sharedComponents: any[] = [];
  public selectedShared: any = null;
  public selectedItem: any = null;
  public selectedLocation: any = null;
  public currentCompany: any = null; // Fake company data
  public searchEGroup: string = ''; // Search input value
  public canShowDescription: boolean = false;
  public canShowAdd: boolean = false;


  @Input() scenario: any = null;
  @Input() vesselScenario: any = null;
  @Input() existingData: any ;
  @Input() public material: any = { name: '', weight: '', unit: '' };
  @Input() public title: string = 'Fuel Option';
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private scenariosStore: Store<{ scenarios: ScenariosState }>,
  ) {
    this.materialOptions$=scenariosStore.select(selectMaterialOptions);
    this.materialOptions$.subscribe(ans=>{
      
    })
  }

  ngOnInit(): void {
    this.scenariosStore.dispatch(ScenariosActions.getLifecycleOptions({title:this.scenario}));
    this.materialOptions$.subscribe(options => {
      if (options && options.length > 0) {
        this.selectedSubGroup = options[0];
        this.showEndGroups(this.selectedSubGroup);
      }
    });
  }


  public toogleSubGroups(): void {
    this.showSub = !this.showSub;
    this.showPrivate = false; // Ensure private groups are hidden when sub-groups are toggled
  }

  public tooglePrivate(): void {
    this.showPrivate = !this.showPrivate;
    this.showSub = false; // Ensure sub-groups are hidden when private groups are toggled
  }

  public showEndGroups(subGroup: any): void {
    this.canShowEndGroups = true;
    this.selectedSubGroup = subGroup;
  }

  selectEndGroup(endGroup){
    this.selectedEndgroup=endGroup;
    this.canShowDetails=true;
  }

  public selectLocation(location: any): void {
    this.selectedLocation = location;
    this.material.location = location.location;
    this.material.locationEmissionId = location.id;
    this.canShowAdd = true;
  }

  public close(): void {
    this.scenariosStore.dispatch(ScenariosActions.toggleMaterialModal({canShow:false}));
  }

  selectCountry(country){
    this.selectedCountry=country;
  }

  public selectOption(option): void {
    const optionToSave={
      optionTitle:option.title,
      optionId:option.id,
      country:this.selectedCountry?.country,
      countryId:this.selectedCountry?.id,
      parentId:this.parentId,
      optionValue:this.existingData?.optionValue!=null?this.existingData?.optionValue:0,
      unit:this.existingData?.unit,
      vesselLifeCycleScenarioId:this.vesselScenario,
      vesselCustomCalculationId:"",
    }
    
    if(this.existingData!=null){
      optionToSave['id']=this.existingData.id;
      this.scenariosStore.dispatch(ScenariosActions.updateOption({option:optionToSave,isUpdating:(this.parentId==0?'parent':'child')}));
    }else{
      this.scenariosStore.dispatch(ScenariosActions.selectOption({option:optionToSave,isAdding:(this.parentId==0?'parent':'child')}));
    }
    this.close();
  }
}
