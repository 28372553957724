<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
       <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
          <label class="clicked-content-title">Fuel option list :</label>
       </ul>
       <form class="form-inline my-2 my-lg-0">
          <div class="row float-right">
             <!-- <div class="inner-addon right-addon">
                <em class="glyphicon fa fa-search"></em>
                <input type="text" class="form-control re-search" placeholder="Search" 
                name="searchTerm" />
             </div> -->
             <div class="col-md-4">
                <button class="btn-solid primary" (click)="showAddModal(true)">
                    <em class="fa fa-plus"></em>Add Option
                    </button>
             </div>
          </div>
       </form>
    </div>
  </nav>
<div class="fuel-list-container">
    <table class="re-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Coefficient</th>
          <th>Percentage</th>
          <th>Category title</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fuel of fuelOptions">
          <td>{{ fuel.title }}</td>
          <td>{{ fuel.coefficient }}</td>
          <td>{{ fuel.percentage }}</td>
          <td>{{ fuel.scenarioCategory?.title }}</td>

          <td>
            <div class="options" ngbDropdown>
              <i class="fa fa-ellipsis-v" aria-hidden="true" ngbDropdownToggle></i>
              <div class="dropdown-menu ddr" ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 200px;">
                <a class="dropdown-item" (click)="showEditeModal(fuel)">
                  <em class="icon-edit" style="margin-right: 10px;"></em>Edit
                </a>
                <a class="dropdown-item" (click)="deleteFuelOption(fuel.id)">
                  <em class="fa fa-trash" style="margin-right: 10px;"></em>Delete
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="fuelOptions.length === 0">No fuel options available.</p>
  </div>
  <app-add-fuel-option (toogleModal)="showAddModal($event)" [showForm]="showFuelOptionModal" *ngIf="showFuelOptionModal"></app-add-fuel-option>
  <app-edit-fuel-option
  *ngIf="showEditModalForm"
  [fuelOption]="selectedFuelOption"
  [showForm]="showEditModalForm"
  (toogleModal)="showEditModalForm = $event"
></app-edit-fuel-option>