import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';

@Component({
  selector: 'app-overview-stats',
  templateUrl: './overview-stats.component.html',
  styleUrls: ['./overview-stats.component.scss']
})
export class OverviewStatsComponent implements OnInit {

  @Input() stats:any=null;
  @Input() count:any=null;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { }

  ngOnInit(): void {
  }

  generateConicGradient(stats: { scope1Percentage?: number; scope2Percentage?: number; scope3Percentage?: number } = {}): string {
    const { scope1Percentage = 0, scope2Percentage = 0, scope3Percentage = 0 } = stats;

    const total = Math.max(0, Math.min(100, scope1Percentage + scope2Percentage + scope3Percentage));
    const normalizedScope1End = (scope1Percentage / total) * 100 || 0;
    const normalizedScope2End = normalizedScope1End + (scope2Percentage / total) * 100 || 0;

    return `conic-gradient(
        #A6DBD9 0% ${normalizedScope1End}%, 
        #6487A2 ${normalizedScope1End}% ${normalizedScope2End}%, 
        #123B63 ${normalizedScope2End}% 100%
    )`;
  }


}
