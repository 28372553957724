import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuelOption } from 'src/app/models/option.model';
import { VesselCalculationInventoryService } from 'src/app/services/danish-shipping-services/vessel-calculation-inventory.service';

@Component({
  selector: 'app-add-fuel-option',
  templateUrl: './add-fuel-option.component.html',
  styleUrls: ['./add-fuel-option.component.css']
})
export class AddFuelOptionComponent implements OnInit {
@Output() toogleModal = new EventEmitter();
@Input() public showForm: boolean;
Options: any[] = [];

public OptionToCreate: FormGroup;

 public submitted = false;
  constructor(
    private fb: FormBuilder,
    private fuelService :VesselCalculationInventoryService 
              
  ) { }

  ngOnInit(): void {
    this.OptionToCreate = this.fb.group({
      title: ["", Validators.required],
      coefficient: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      percentage: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scenarioCategoryId: [null, Validators.required],
      scenarioCategoryTitle: ["", Validators.required],

    });
    this.loadOptions();

  }
  onScenarioCategoryChange(selectedTitle: string): void {
    const selectedCategory = this.Options.find(option => option.scenarioCategory?.title === selectedTitle);
    if (selectedCategory) {
      this.OptionToCreate.patchValue({
        scenarioCategoryId: selectedCategory.scenarioCategory.id
      });
    }
  }
  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.OptionToCreate.valid) {
      const Option: FuelOption = this.OptionToCreate.value;

      this.fuelService.addOption(Option).subscribe(
        (response) => {
          console.log('Option Added Successfully', response);
          window.location.reload();
        },
        (error) => {
          console.error('Error adding  option', error);
        }
      );
    }}
    loadOptions(): void {
      this.fuelService.getOptions().subscribe(
        (data) => {
          this.Options = data;
        },
        (error) => {
          console.error('Error loading options:', error);
        }
      );
    }
}
