import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuelOption } from 'src/app/models/option.model';
import { VesselCalculationInventoryService } from 'src/app/services/danish-shipping-services/vessel-calculation-inventory.service';

@Component({
  selector: 'app-edit-fuel-option',
  templateUrl: './edit-fuel-option.component.html',
  styleUrls: ['./edit-fuel-option.component.css']
})
export class EditFuelOptionComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public showForm: boolean = false;
  @Input() public fuelOption: FuelOption;
  public OptionToEdit: FormGroup;
  public scenarioCategories: any[] = [];
  public submitted = false;
  constructor(
    private fb: FormBuilder,
    private fuelService: VesselCalculationInventoryService
  ) {
   }

   ngOnInit(): void {
    this.OptionToEdit = this.fb.group({
      id: [this.fuelOption.id],
      title: [this.fuelOption.title, Validators.required],
      coefficient: [
        this.fuelOption.coefficient,
        [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')],
      ],
      percentage: [
        this.fuelOption.percentage,
        [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')],
      ],
      scenarioCategoryId: [this.fuelOption.scenarioCategoryId, Validators.required],
      scenarioCategoryTitle: [this.fuelOption.scenarioCategory?.title, Validators.required], // Utiliser le titre au lieu de l'ID

    });
    this.fuelService.getOptions().subscribe(
      (scenarios) => {
        // Extraire les catégories uniques
        const categories = scenarios
          .map((scenario) => scenario.scenarioCategory) // Extraire les catégories
          .filter((category, index, self) => category && self.findIndex(c => c.id === category.id) === index); // Supprimer les doublons
        this.scenarioCategories = categories;
      },
      (error) => {
        console.error('Error fetching scenarios:', error);
      }
    );
  }
  public hideModal(): void {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.OptionToEdit.valid) {
      const updatedFuelOption: FuelOption = this.OptionToEdit.value;

      this.fuelService.updateOption(updatedFuelOption).subscribe(
        (response) => {
          console.log('Fuel Option Updated Successfully', response);
          this.hideModal();
        },
        (error) => {
          console.log('Error updating fuel option', error);
        }
      );
    }
  }
  

}
