import { createReducer, on } from '@ngrx/store';
import { VesselsOverviewActions } from '../actions/vessels-overview.actions';
import { ScenariosActions } from '../actions/scenarios.actions';
import { loadScenariosFromLocalStorage } from '../utils/scenario-storage.util';


export interface ScenariosState {
    baseScenarioData:any,
    baselineMetaData:any,
    vesselData:any,
    scenariosData:any,
    scenarioInventories:any,
    scenarioInventories_children:any,
    currentStep:any,
    resultScopes:any,

    showMaterialModal:boolean|null,
    materialOptions:any,
    selectedOption:any,
    error:any
}
  
export const initialScenariosState: ScenariosState = {
    baseScenarioData:null,
    baselineMetaData:null,
    vesselData:null,
    scenarioInventories:[],
    scenariosData:{},
    scenarioInventories_children:{},
    currentStep:0,
    resultScopes:{
        scope1:null,
        scope2:null,
        scope3:null,
    },

    showMaterialModal:false,
    materialOptions:[],
    selectedOption:null,
    error:null
};

export const ScenariosReducer = createReducer(
    initialScenariosState,


    on(ScenariosActions.setScenariosState, (state, { scenarios }) => ({ 
        ...state, 
        ...scenarios,
        resultScopes: {
            ...state.resultScopes,
            ...scenarios.resultScopes,
          },
        /*baseScenarioData:scenarios.baseScenarioData,
        baselineMetaData:scenarios.baselineMetaData,
        vesselData:scenarios.vesselData,
        scenarioInventories:scenarios.scenarioInventories,
        scenariosData:scenarios.scenariosData,
        scenarioInventories_children:scenarios.scenarioInventories_children,
        currentStep:scenarios.currentStep,

        showMaterialModal:scenarios.showMaterialModal,
        materialOptions:scenarios.materialOptions,
        selectedOption:scenarios.selectedOption,
        error:scenarios.error*/
    })),
    on(ScenariosActions.clearScenariosState, () => initialScenariosState),


    on(ScenariosActions.setScenarios, (state, { scenarios }) => ({
         ...state, 
        vesselData:scenarios.vesselData ,
        baselineMetaData:scenarios.baselineMetaData
    })),
    on(ScenariosActions.fetchBaselineBuildingScenarioSuccess,  (state, { baseScenarioData }) => ({
        ...state, 
        baseScenarioData: baseScenarioData,
        error:null
    })),
    on(ScenariosActions.fetchBaselineBuildingScenarioFailed,  (state, { error }) => ({
        ...state, 
        baseScenarioData: null,
        error,
    })),

    on(ScenariosActions.toggleMaterialModal,  (state, { canShow }) => ({
        ...state, 
        showMaterialModal:canShow
    })),

    on(ScenariosActions.getLifecycleOptionsSuccess,  (state, { options }) => ({
        ...state, 
        materialOptions:options.scenarioCategories
    })),
    on(ScenariosActions.getLifecycleOptionsFailed,  (state, { error }) => ({
        ...state, 
        error:error
    })),


    on(ScenariosActions.fetchBaseScenariosSuccess,  (state, { data }) => ({
        ...state, 
        baselineMetaData: data,
        error:null
    })),
    on(ScenariosActions.fetchBaseScenariosFailed,  (state, { error }) => ({
        ...state, 
        baselineMetaData: null,
        error,
    })),
    
    on(ScenariosActions.selectOptionSuccess,  (state, { option }) => ({
        ...state, 
        selectedOption: option,
        scenarioInventories:[...state.scenarioInventories, option],
        scenarioInventories_children:{
            ...state.scenarioInventories_children,
            [option.id]:null,
        },
        error:null
    })),
    on(ScenariosActions.selectChildOptionSuccess,  (state, { option }) => ({
        ...state, 
        selectedOption: option,
        scenarioInventories_children:{
            ...state.scenarioInventories_children,
            [option.parentId]:option,
        },
        error:null
    })),
    on(ScenariosActions.fetchBaseScenariosFailed,  (state, { error }) => ({
        ...state, 
        selectedOption: null,
        error,
    })),

    on(ScenariosActions.getScenarioInventoriesSuccess,  (state, { parent,child }) => ({
        ...state, 
        selectedOption: null,
        scenarioInventories:parent,
        scenarioInventories_children:child,
        error:null
    })),
    on(ScenariosActions.getScenarioInventoriesForResultSuccess,  (state, { data,step }) => ({
        ...state, 
        scenariosData:{
            ...state.scenariosData,
            [step]:data
        },
        error:null
    })),
  
    on(ScenariosActions.getScenarioInventoriesFailed,  (state, { error }) => ({
        ...state, 
        selectedOption: null,
        scenarioInventories:[],
        error,
    })),

    on(ScenariosActions.updateOptionSuccess,  (state, { option }) => ({
        ...state, 
        selectedOption: null,
        scenarioInventories: state.scenarioInventories.map(item =>
            item.id === option.id ? { ...option } : item
          ),
        error:null
    })),
    on(ScenariosActions.updateChildOptionSuccess,  (state, { option }) => ({
        ...state, 
        selectedOption: null,
        scenarioInventories_children: {
            ...state.scenarioInventories_children,
            [option.parentId]:option,
        },
        error:null
    })),
    on(ScenariosActions.updateOptionFailed,  (state, { error }) => ({
        ...state, 
        error,
    })),

    on(ScenariosActions.deleteOptionSuccess,  (state, { id }) => ({
        ...state, 
        selectedOption: null,
        scenarioInventories: state.scenarioInventories.filter(x=>x.id!=id),
        error:null
    })),
    on(ScenariosActions.deleteOptionFailed,  (state, { error }) => ({
        ...state, 
        error,
    })),

    on(ScenariosActions.deleteChildOptionSuccess, (state, { parentId }) => ({
        ...state,
        selectedOption: null,
        scenarioInventories_children: (({ [parentId]: _, ...remainingChildren }) => remainingChildren)(state.scenarioInventories_children),
        error: null,
    })),
    on(ScenariosActions.deleteChildOptionFailed, (state, { error }) => ({
        ...state,
        error,
    })),

    on(ScenariosActions.confirmStepSuccess,  (state, { result }) => ({
        ...state, 
        currentStep:state.currentStep+1,
        scenarioInventories:[],
        scenarioInventories_children:[],
        error:null
    })),
    on(ScenariosActions.confirmStepFailed,  (state, { error }) => ({
        ...state, 
        error,
    })),
    on(ScenariosActions.previousStepSuccess, (state, { result }) => ({
        ...state,
        currentStep: state.currentStep - 1,
        scenarioInventories: [],
        scenarioInventories_children: [],
        error: null
    })),
    on(ScenariosActions.previousStepFailed, (state, { error }) => ({
        ...state,
        error,
    })),
    on(ScenariosActions.initStep,  (state) => ({
        ...state, 
        currentStep:0
    })),

    on(ScenariosActions.getVesselDataSuccess,  (state, { vesselData }) => ({
        ...state, 
        vesselData:vesselData,
    })),
    on(ScenariosActions.getVesselDataFailed,  (state, { error }) => ({
        ...state, 
        vesselData:null,
        error,
    })),

    on(ScenariosActions.getScope1ImpactSuccess,  (state, { data }) => ({
        ...state, 
        resultScopes:{
            ...state.resultScopes,
            ['scope1']:data.data
        },
    })),
    on(ScenariosActions.getScope2ImpactSuccess,  (state, { data }) => ({
        ...state, 
        resultScopes:{
            ...state.resultScopes,
            ['scope2']:data.data
        },
    })),
    on(ScenariosActions.getScope3ImpactSuccess,  (state, { data }) => ({
        ...state, 
        resultScopes:{
            ...state.resultScopes,
            ['scope3']:data
        },
    })),
    on(ScenariosActions.getScope1ImpactFailed,  (state, { error }) => ({
        ...state, 
        ['scope3']:null,
        error,
    })),
    on(ScenariosActions.getScope2ImpactFailed,  (state, { error }) => ({
        ...state, 
        ['scope2']:null,
        error,
    })),
    on(ScenariosActions.getScope3ImpactFailed,  (state, { error }) => ({
        ...state, 
        ['scope3']:null,
        error,
    })),
);



