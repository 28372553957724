import { createAction, props } from '@ngrx/store';

export const setScenarios = createAction(
    '[Scenarios] Set Scenarios',
    props<{ scenarios: any }>()
  );

export const fetchBaselineBuildingScenario = createAction(
    "[VLS - Baseline building scenario] fetch Baseline building scenario data",
    props<{id:any}>()
);
export const fetchBaselineBuildingScenarioSuccess = createAction(
    "[VLS - Baseline building scenario] fetch Baseline building scenario data success",
    props<{baseScenarioData:any}>()
);
export const fetchBaselineBuildingScenarioFailed = createAction(
    "[VLS - Baseline building scenario] fetch Baseline building scenario data failed",
    props<{error:any}>()
);

export const fetchBaseScenarios = createAction(
    "[VLS - Baseline scenario] fetch base scenarios data",
    props<{id:any}>()
);
export const fetchBaseScenariosSuccess = createAction(
    "[VLS - Baseline scenario] fetch base scenarios data success",
    props<{data:any}>()
);
export const fetchBaseScenariosFailed = createAction(
    "[VLS - Baseline scenario] fetch base scenarios data failed",
    props<{error:any}>()
);


export const toggleMaterialModal=createAction(
    "[baseline scenario - step2] toggle material modal",
    props<{canShow:boolean}>()
)

export const getLifecycleOptions=createAction(
    "[Material modal- step2] get lifecycle options",
    props<{title:any}>()
)
export const getLifecycleOptionsSuccess=createAction(
    "[Material modal- step2] get lifecycle options success",
    props<{options:any}>()
)
export const getLifecycleOptionsFailed=createAction(
    "[Material modal- step2] get lifecycle options failed",
    props<{error:any}>()
)



export const getScenarioInventories=createAction(
    "[Baseline scenario - step2] get scenario inventories",
    props<{id:any}>()
)
export const getScenarioInventoriesSuccess=createAction(
    "[Baseline scenario - step2] get scenario inventories success",
    props<{parent:any,child:any}>()
)
export const getScenarioInventoriesFailed=createAction(
    "[Baseline scenario - step2] get scenario inventories failed",
    props<{error:any}>()
)

export const getScenarioInventoriesForResult=createAction(
    "[vessel result] get scenario inventories",
    props<{id:any,step:any}>()
)

export const getScenarioInventoriesForResultSuccess=createAction(
    "[vessel result] get scenario inventories success",
    props<{data:any,step:any}>()
)



export const updateOption=createAction(
    "[Baseline scenario - step2] update option",
    props<{option:any, isUpdating:string}>()
)
export const updateOptionSuccess=createAction(
    "[Baseline scenario - step2] update option success",
    props<{option:any}>()
)
export const updateChildOptionSuccess=createAction(
    "[Baseline scenario - step2] update child option success",
    props<{option:any}>()
)
export const updateOptionFailed=createAction(
    "[Baseline scenario - step2] update option failed",
    props<{error:any}>()
)


export const deleteOption=createAction(
    "[Baseline scenario - step2] delete option",
    props<{id:any}>()
)
export const deleteOptionSuccess=createAction(
    "[Baseline scenario - step2] delete option success",
    props<{id:any}>()
)
export const deleteOptionFailed=createAction(
    "[Baseline scenario - step2] delete option failed",
    props<{error:any}>()
)

export const deleteChildOption = createAction(
    "[Baseline scenario - step2] delete child option",
    props<{ parentId: any; childId: any }>()
);
export const deleteChildOptionSuccess = createAction(
    "[Baseline scenario - step2] delete child option success",
    props<{ parentId: any }>()
);
export const deleteChildOptionFailed = createAction(
    "[Baseline scenario - step2] delete child option failed",
    props<{ error: any }>()
);

export const selectOption=createAction(
    "[Material modal- step2] save option from modal",
    props<{option:any, isAdding:string}>()
)

export const selectOptionSuccess=createAction(
    "[Material modal- step2] save option from modal success",
    props<{option:any}>()
)
export const selectChildOptionSuccess=createAction(
    "[Material modal- step2] save child option from modal success",
    props<{option:any}>()
)
export const selectOptionFailed=createAction(
    "[Material modal- step2] save option from modal failed",
    props<{error:any}>()
)

export const initStep=createAction(
    "[baseline scenario] init Steps"
)
export const confirmStep=createAction(
    "[baseline scenario] confirm Step",
    props<{scenarioId:any}>()
)
export const confirmStepSuccess=createAction(
    "[baseline scenario] confirm Step success",
    props<{result:any}>()
)
export const confirmStepFailed=createAction(
    "[baseline scenario] confirm Step failed",
    props<{error:any}>()
)

export const previousStep = createAction(
  "[baseline scenario] previous Step",
  props<{ scenarioId: any }>()
);

export const previousStepSuccess = createAction(
  "[baseline scenario] previous Step success",
  props<{ result: any }>()
);

export const previousStepFailed = createAction(
  "[baseline scenario] previous Step failed",
  props<{ error: any }>()
);

export const getVesselData=createAction(
    "[result page] get vessel data",
    props<{vesselId:any}>()
)
export const getVesselDataSuccess=createAction(
    "[result page] get vessel data success",
    props<{vesselData:any}>()
)
export const getVesselDataFailed=createAction(
    "[result page] get vessel data failed",
    props<{error:any}>()
)



export const getScope1Impact=createAction(
    "[result page] get scope1 data",
    props<{id:any}>()
)
export const getScope1ImpactSuccess=createAction(
    "[result page] get vessel scope1 success",
    props<{data:any}>()
)
export const getScope1ImpactFailed=createAction(
    "[result page] get vessel scope1 failed",
    props<{error:any}>()
)

export const getScope2Impact=createAction(
    "[result page] get scope2 data",
    props<{id:any}>()
)
export const getScope2ImpactSuccess=createAction(
    "[result page] get vessel scope2 success",
    props<{data:any}>()
)
export const getScope2ImpactFailed=createAction(
    "[result page] get vessel scope2 failed",
    props<{error:any}>()
)

export const getScope3Impact=createAction(
    "[result page] get scope3 data",
    props<{id:any,vesselType:any}>()
)
export const getScope3ImpactSuccess=createAction(
    "[result page] get vessel scope3 success",
    props<{data:any}>()
)
export const getScope3ImpactFailed=createAction(
    "[result page] get vessel scope3 failed",
    props<{error:any}>()
)


export const setScenariosState = createAction('[Scenarios] Set Scenarios', props<{ scenarios: any }>());
export const clearScenariosState = createAction('[Scenarios] Clear Scenarios');

export const ScenariosActions={
    setScenariosState,
    clearScenariosState,
    setScenarios,
    getScenarioInventoriesForResult,
    getScenarioInventoriesForResultSuccess,

    getScope1Impact,
    getScope1ImpactSuccess,
    getScope1ImpactFailed,
    getScope2Impact,
    getScope2ImpactSuccess,
    getScope2ImpactFailed,
    getScope3Impact,
    getScope3ImpactSuccess,
    getScope3ImpactFailed,
    
    getVesselData,
    getVesselDataSuccess,
    getVesselDataFailed,

    initStep,
    confirmStep,
    confirmStepSuccess,
    confirmStepFailed,

    previousStep,
    previousStepSuccess,
    previousStepFailed,

    fetchBaselineBuildingScenario,
    fetchBaselineBuildingScenarioSuccess,
    fetchBaselineBuildingScenarioFailed,

    fetchBaseScenarios,
    fetchBaseScenariosSuccess,
    fetchBaseScenariosFailed,

    toggleMaterialModal,

    getLifecycleOptions,
    getLifecycleOptionsSuccess,
    getLifecycleOptionsFailed,

    selectOption,
    selectOptionSuccess,
    selectChildOptionSuccess,
    selectOptionFailed,

    
    getScenarioInventories,
    getScenarioInventoriesSuccess,
    getScenarioInventoriesFailed,

    updateOption,
    updateOptionSuccess,
    updateChildOptionSuccess,
    updateOptionFailed,

    deleteOption,
    deleteOptionSuccess,
    deleteOptionFailed,

    deleteChildOption,
    deleteChildOptionSuccess,
    deleteChildOptionFailed
}