import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, timer, Subscription } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UserState } from '../store/reducers/user.reducers';
import { selectUserId, selectVerificationCode } from '../store/selectors/user.selectors';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpTrackerService {
  private activeRequests = 0;
  private requestStatus = new BehaviorSubject<boolean>(true); 
  private timerSubscription?: Subscription; 
  private userId?: string;
  private verificationCode?: any;

  constructor(
    private userService: UserService, 
    private store: Store<{ user: UserState }>,
    private auth: AuthService,
  ) {
    this.store.select(selectUserId).subscribe(userId => {
      this.userId = userId; 
    });

    this.store.select(selectVerificationCode).subscribe(vc => {
      this.verificationCode = vc; 
    });

    this.startTracking();
  }

  private startTracking() {
    this.requestStatus.pipe(
      filter(isIdle => isIdle), 
      tap(() => this.cancelTimer()), 
      switchMap(() => timer(3000))
    ).subscribe(() => {
      this.triggerUserDataCheck();
    });
  }

  requestStarted() {
    this.activeRequests++;
    this.requestStatus.next(false);
    this.cancelTimer(); 
  }

  requestCompleted() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.requestStatus.next(true); 
    }
  }

  private triggerUserDataCheck() {
    if (this.timerSubscription) return;

    if (!this.userId) {
      console.warn('User ID not available for data check');
      return;
    }

    this.timerSubscription = this.userService.checkUserPassData(this.userId).subscribe(data => {
      if(this.verificationCode+""!=data.code+""){
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('error',"Session expired");
        localStorage.setItem('error--v',"Session expired")
        this.auth.logout({ returnTo: `${window.location.origin}/error` });
      }
      this.timerSubscription = undefined; 
    });
  }

  private cancelTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }
}