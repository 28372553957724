import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpTrackerService } from './http-tracker.service';

@Injectable()
export class HttpTrackerInterceptor implements HttpInterceptor {
  constructor(private httpTrackerService: HttpTrackerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api/user/pass-data')) {
      return next.handle(req);
    }

    this.httpTrackerService.requestStarted();
    
    return next.handle(req).pipe(
      finalize(() => this.httpTrackerService.requestCompleted())
    );
  }
}