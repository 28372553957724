import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { verificationCodeDto } from '../models/dtos.model';
import { UserService } from '../services/user.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '@auth0/auth0-angular';
@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {
  public showLogin = true;
  public showSignUp1 = true;
  public showSignUp2 = false;
  public showResetPwd1 = false;
  public showResetPwd2 = false;
  public showResetPwd3 = false;
  public showLoginstep = true;
  public showsignupstep = false;
  public showPricingTable = false;
  public showLogin2 = false;
  public showIncorrectCode = false;
  public loginResult : any;
  public loginFailed : boolean = false;
  public emailforreset="";
  public resetcode="";
  public intendedUserId=null;
  public passwordForRest="";
  public confirmPasswordForReset="";
  public showpassword = false;
  public fieldType = "password";
  public iconType="icon-view";
  public siteKey = "6Lez6NMZAAAAAMD7ZBqIH-0Zj4fHvqNkwFy7th83";
  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "en";
  public type: "image" | "audio";
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public showpasswordpassword = false;
  public fieldTypePassword = "password";
  public iconTypePassword="icon-view";
  public current_year = new Date().getFullYear();
  public showpasswordpasswordconfirm = false;
  public fieldTypePasswordC = "password";
  public iconTypeC="icon-view";
  public showOldForm=false;
  public currentUser: any;
  interactionInProgress = false;

  public loginFormGroup : FormGroup;
  public  isLoggingIn = false;
  constructor(
    private router : Router,
    private toasterService: ToastrService,
    private auth: AuthService,
    
    ) {
      
  }
  ngOnInit() {
    
  }
  
  public navigateFromLogin(): void {
    this.router.navigate([this.currentUser.redirecturl]);
  }


  loginWithEmail() {
    this.auth.loginWithRedirect({
      authorizationParams: {
        connection: 'email', 
        send: 'link',       
      },
      appState: { target: '/redirection' }
    });
  }

}
